import { useEffect } from "react";
import { getSocket } from "../socket";
import { useRecoilValue } from "recoil";
import authAtom from "../recoil/auth/atom";

const useSocket = () => {
  const auth = useRecoilValue(authAtom);

  useEffect(() => {
    getSocket(auth.access_token);
  }, []);

  return getSocket(auth.access_token);
};

export default useSocket;
