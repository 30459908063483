import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Box, Stack, Typography } from "@mui/material";
import useSocket from "../../hooks/useSocket";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { useAxios } from "../../hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudSyncRoundedIcon from "@mui/icons-material/CloudSyncRounded";
import SocketEvents from "../../utilities/socketEvent";
import authAtom from "../../recoil/auth/atom";

export default function ReportAction() {
  const auth = useRecoilValue(authAtom);
  const api = useAxios();
  const socket = useSocket(auth);
  const openAlert = useSetRecoilState(withAlert);

  const [startValue, setStartValue] = useState(dayjs("2024-04-17"));
  const [endValue, setEndValue] = useState(dayjs("2024-04-17"));
  const today = new Date();

  const [fileData, setFileData] = useState(null);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);

  const { mutate: generateReport, isLoading: isGenerating } = useMutation(
    () => {
      const startDateString = `${startValue.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      )}`;
      const endDateString = `${endValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}`;
      return api.get(
        `/api/report/usage-report?start_date=${startDateString}&end_date=${endDateString}`
      );
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: 200,
          detail: "Report generation started....",
        });
        setFileData(res.data);
        setIsDatePickerDisabled(true);
      },
    }
  );

  const { mutate: downloadReport, isLoading: isDownloading } = useMutation(
    () =>
      api.get(`/api/report/usage-report-download?file_name=${fileData}`, {
        responseType: "blob",
      }),
    {
      onSuccess: (response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileData?.split(".")[0]}.zip`;
        a.click();
        URL.revokeObjectURL(url);
        openAlert({
          status: 200,
          detail: "Report downloaded successfully.",
        });
        setFileData(null);
        setIsDatePickerDisabled(false);
      },
    }
  );

  const handleReportAction = () => {
    if (fileData === null) {
      generateReport();
    } else {
      downloadReport();
    }
  };

  useEffect(() => {
    if (fileData !== null) {
      setIsDatePickerDisabled(true);
    }
  }, [fileData, startValue, endValue]);

  useEffect(() => {
    if (!socket) return;
    const handleFileUpdate = (data) => {
      setFileData(data);
    };
    socket.on(SocketEvents.REPORT_STATUS_UPDATED, handleFileUpdate);
    return () => {
      socket.off(SocketEvents.REPORT_STATUS_UPDATED, handleFileUpdate);
    };
  }, [socket]);

  return (
    <>
      <Box sx={{
        mb:"2.5rem"
      }}>
        <Typography
          fontWeight="bold"
          color="primary"
          sx={{ my: "0.5rem", fontSize: "1.2rem" }}
        >
          Monthly Usage Report
        </Typography>
        <Typography>
          You can generate and download monthly usage reports with date range here!
        </Typography>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" gap={4} flexWrap="wrap">
          <DatePicker
            format="YYYY-MM-DD"
            value={startValue}
            onChange={(newValue) => setStartValue(newValue)}
            renderInput={(params) => <TextField {...params} />}
            label="Start Date"
            slotProps={{ textField: { size: "small" } }}
            disabled={isDatePickerDisabled}
          />
          <DatePicker
            format="YYYY-MM-DD"
            value={endValue}
            onChange={(newValue) => setEndValue(newValue)}
            maxDate={dayjs(today)}
            renderInput={(params) => <TextField {...params} />}
            label="End Date"
            slotProps={{ textField: { size: "small" } }}
            disabled={isDatePickerDisabled}
          />
          <LoadingButton
            disableElevation
            variant="contained"
            onClick={handleReportAction}
            loading={isGenerating || isDownloading}
            disabled={isGenerating || isDownloading}
          >
            {fileData !== null ? (
              <>
                <CloudDownloadIcon /> &nbsp; Download Report
              </>
            ) : (
              <>
                <CloudSyncRoundedIcon /> &nbsp; Generate Report
              </>
            )}
          </LoadingButton>
        </Stack>
      </LocalizationProvider>
    </>
  );
}
